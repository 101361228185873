<template>
    <div class="top" :style="{'height':topHeight +'px'}">
      <div class="flex-vertical" style="width:20%;"> 
        <div class="flex-vertical" style="margin-left:10px;">
          <img :src="sysLogoUrl" style="width:100px;height:50px;"/>
        </div>
      </div>
      <div class="flex-center" style="width:60%;"> 
        <span style="text-align:center;font-weight: bold;font-size: 20px;color:white">{{sysName}}数据中心</span> 
      </div>
      <div class="flex-right" style="width: 20%;">
        <img style="margin-right:10px;" src="../../../assets/img/fullscreen.png" @click="magnify()"/>
      </div>
    </div>

    <div class="content"> 

      <div class="flex-space-between frame-color" style="padding: 10px;" :style="{'height':middleHeight +'px'}"> 
        <div class="box"> 
          <div class="small-box" style="height: 54%;"> 

            <div class="left-box" style="height:45%;">
              <div class="title">
                <span class="title_ch">用户数据</span>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                      <span>{{ this.user.total }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>用户总数</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span>{{this.user.male}}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>男</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span>{{ this.user.female }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>女</span> 
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- <div style="height:0.1px;background-color: #e8e8ed;"></div> -->
            <div class="left-box" style="height:55%;"> 
              <div style="display: flex; align-items: center">
                <div id="ageDataPie" class="pie-style"></div>
              </div>
            </div>


          </div>

          <div class="small-box"  style="height: 45%;margin-top: 5px;"> 

            <div class="title">
              <span class="title_ch">健康数据</span>
            </div>
            <div style="display: flex; align-items: center">
              <div id="healthDataPie" class="pie-style" style="height:200px;"></div>
            </div>
          </div>

        </div>

        <div class="map" style="position:relative;" :style="{'height':middleHeight +'px'}">
          <div >
            <el-collapse-transition>
              <div style="position: absolute;  top: 5px;left: 5px;z-index: 1;">
                <el-button :plain="userPlain" type="primary"  style="margin-left:10px;" @click="userClick">用户</el-button>
                <el-button :plain="devicePlain" type="primary"  style="margin-left:10px;" @click="deviceClick">设备</el-button>
              </div>
            </el-collapse-transition>
          </div>
          <div id="map" class='amap-wrapper' style="position:absolute;"></div>
        </div>

        <div class="box"> 
          <div class="small-box" style="height: 49%;"> 
            <div class="left-box" style="height:45%;">
              <div class="title">
                <span class="title_ch">设备数据</span>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                      <span>{{this.device.total}}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>总数</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span>{{this.deviceState.onLine }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>在线</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span style="color:red">{{this.deviceState.offLine }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>离线</span> 
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="left-box" style="height:55%;"> 
              <div style="display: flex; align-items: center">
                <div id="deviceDataPie" class="pie-style" style="height:150px"></div>
              </div>
            </div>

          </div>

          <div class="small-box"  style="height: 50%;margin-top: 5px;"> 
            <div class="left-box" style="height:45%;">
              <div class="title">
                <span class="title_ch">预警数据</span>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span>{{ this.warningState.total }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>总数</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span>{{ this.warningState.processed }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>已处理</span> 
                    </div>
                  </div>
                </div>
              </div>

              <div class="three-box">
                <div class="half-number-box ">
                  <div style="align-self: center;width:100%;">
                    <div style="font-size: 25px;color: #2C61EF;text-align: center;font-weight:bold;">
                    <span style="color:red">{{ this.warningState.notProcessed }}</span>
                    </div>
                    <div style="width:100%;font-size: 13px;text-align: center;margin-top: 1px;color: white;">
                      <span>未处理</span> 
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="left-box" style="height:55%;"> 
              <div style="display: flex; align-items: center">
                <div id="warningDataPie" class="pie-style" style="height:150px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-space-between frame-color" style="padding: 0px 10px 10px 10px;min-height:300px" :style="{'height':bottomHeight +'px'}"> 

        <div class="half-box1"> 
          <div class="title">
            <span class="title_ch">预警数据</span>

            <div style="float:right">
              <span class="title_en">类型:</span>
              <el-select v-model="dateType"  placeholder="请选择" style="width: 90px"  size="small" @change="changDateType($event)" >
                <el-option label="日报表" value="1"></el-option>
                <el-option label="月报表" value="2"></el-option>
              <!-- <el-option label="季报表" value="3"></el-option>
              <el-option label="年报表" value="4"></el-option> -->
              </el-select>
              <span v-if="dateType == 1" class="title_en" style="margin-left:10px;">月份:</span>
              <el-config-provider :locale="zhCn">
              <el-date-picker v-if="dateType == 1"   @change="changMonth($event)"  v-model="monthPicker" type="month" placeholder="选择月"  style="width: 160px" format="YYYY 年 MM 月"
                value-format="YYYY-MM"  size="small" :clearable="false"></el-date-picker>
              <span v-if="dateType == 2 || dateType == 3 || dateType == 4 " class="title_en" style="margin-left:10px;">年份:</span>
              <el-date-picker v-if="dateType == 2 || dateType == 3 || dateType == 4 "  @change="changYear($event)" v-model="yearPicker" type="year" placeholder="选择年"
                style="width: 160px" format="YYYY 年" value-format="YYYY" size="small" :clearable="false"></el-date-picker>
              </el-config-provider>
            </div>
          </div>
          <div id="warningReportData" class="line-style" ></div>
        </div>

        <div class="half-box1" > 

          <div v-if="this.role == 1"> 
            <div class="title">
              <span class="title_ch">机构数据</span>
            </div>
            <div id="unitData" class="category-style" ></div>
          </div>
          <div v-if="this.role != 1"> 
            <div class="title">
              <span class="title_ch">预警记录</span>
            </div>
            <div class="table-box" style="display: flex; align-items: center;">
              <el-table :data="dataList"  :show-header="false" :row-style="rowStyle" :row-class-name="cellClassName"  :empty-text="emptyText">
                <el-table-column type="index" label="序号" width="55" label-class-name="tablehead" align="center" />
                <el-table-column prop="createTime" label="时间" label-class-name="tablehead" align="center" >
                  <template #default="scope">
                    <span>{{scope.row.createTime.substring(0,16)}}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="warningTypeDescribe" label="预警类型" label-class-name="tablehead" align="center" />

                <el-table-column prop="dealState" label="状态" width="70" label-class-name="tablehead" align="center" >
                  <template #default="scope">
                    <span v-if="scope.row.dealState == 1" style="color:red">未处理</span>
                    <span v-if="scope.row.dealState == 2" style="color:green">已处理</span>
                    <span v-if="scope.row.dealState == 3" style="color:#FFB640">已忽略</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script src='./js/user-main-full-screen-report.js'></script>

<style scoped src="./css/user-main-full-screen-report.css"></style>

<style>
  .amap-info-content {
    overflow: hidden;
    padding: 0px;
  }
  .table-box .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0px;
  }
  .table-box .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 2px;
    height: 50px;
    /* background: #eee; */
  }
  .table-box .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    /* background: rgba(0, 0, 0, 0.4); */
  }

  .table-box .el-table__row>td{
    border: none;
  }
  .table-box .el-table::before {
    height: 0px;
    }

  .table-box .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden;
  }

  .table-box .el-table{
    background-color: #071738;
  }
  .table-box .report-row{
    background-color: #071738;
  }
  .table-box .el-table--enable-row-hover .el-table__body tr:hover > td {
   background-color: #071738;
}

  .tablehead {
    color: white;
  }

  ::-webkit-scrollbar {
   display: none;
 }

</style>